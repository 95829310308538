import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { WordpressMarkupParser } from 'utils/markupParser'

class PageRegular extends React.Component {
  render () {
    const { page } = this.props
    return (
      <div className='page-container'>
        <Head>
          {!page.acf.title && <title>{page.title.rendered} - Ordnett skole</title>}
          {page.acf.title && <title>{page.acf.title}</title>}
          {page.acf.description && <meta name='description' content={page.acf.description} />}
          {page.acf.title && <meta property='og:title' content={page.acf.title} />}
          {!page.acf.title && <meta property='og:title' content={page.title.rendered} />}
          {page.acf.description && <meta property='og:description' content={page.acf.description} />}
          {page.acf.image_og && <meta property='og:image' content={page.acf.image_og} />}
          {<meta property='og:url' content={page.link.replace('https://cms.skoleordbok.no', 'https://skole.ordnett.no')} />}
          {page.acf.title && <meta name='twitter:title' content={page.acf.title} />}
          {!page.acf.title && <meta name='twitter:title' content={page.title.rendered} />}
          {page.acf.description && <meta name='twitter:description' content={page.acf.description} />}
          {page.acf.image_twitter && <meta name='twitter:image' content={page.acf.image_twitter} />}
          {<meta name='twitter:card' content='summary_large_image' />}
        </Head>
        {page && !page.acf.display_title && <h1>{page.title.rendered}</h1>}
        {page && page.acf.display_title && <h1>{page.acf.display_title}</h1>}
        {page && WordpressMarkupParser(`<div className='cms-content'>${page.content.rendered}</div>`)}
        <style>{`
        h1, h2, h3 {
          text-align: left;
        }
        `}</style>
      </div>
    )
  }
}

PageRegular.propTypes = {
  page: PropTypes.object
}

export default PageRegular
