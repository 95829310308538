import React from 'react'
import PropTypes from 'prop-types'

const DictElement = ({ selected = false, setScrollToEle, children, ...rest }) => {
  const scrollToRef = selected ? setScrollToEle : null
  return (
    <div ref={scrollToRef} className={`container ${selected ? 'containerSelected' : ''}`} {...rest}>
      {children}
      <style jsx>{`
      .containerSelected {
        box-shadow: 0 0 6px rgba(0,0,0,.5);
        background: #fff;
      }
      `}</style>
    </div>
  )
}

DictElement.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.any,
  setScrollToEle: PropTypes.func
}

export default DictElement
