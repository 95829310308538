import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { WordpressMarkupParser } from 'utils/markupParser'
import colors from 'styles/colors'
import Head from 'next/head'
import Button from 'components/Button'

class PageFront extends React.Component {
  render () {
    const { page } = this.props
    return (
      <div className='wrapper'>
        <Head>
          {page.acf.title && <title>{page.acf.title}</title>}
          {page.acf.description && <meta name='description' content={page.acf.description} />}
          {page.acf.title && <meta property='og:title' content={page.acf.title} />}
          {page.acf.description && <meta property='og:description' content={page.acf.description} />}
          {page.acf.image_og && <meta property='og:image' content={page.acf.image_og} />}
          {<meta property='og:url' content='https://skole.ordnett.no' />}
          {page.acf.title && <meta name='twitter:title' content={page.acf.title} />}
          {page.acf.description && <meta name='twitter:description' content={page.acf.description} />}
          {page.acf.image_twitter && <meta name='twitter:image' content={page.acf.image_twitter} />}
          {<meta name='twitter:card' content='summary_large_image' />}
        </Head>
        <div className='content-header'>
          <div className='content-header-text'>
            <div className='text-inner'>
              <h1>{page.acf.heading}</h1>
              {WordpressMarkupParser(`<div className='content-header-text'>${page.content.rendered}</div>`)}
            </div>
          </div>
        </div>
        {page.acf.ingress && <div className='another-wrapper'>
          {page.acf.you_tube && <div className='frontpage-video'>
            <iframe width='100%' height='100%' src={`${page.acf.you_tube}?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;' allowFullScreen />
          </div>}
          <div className='frontpage-text'>
            <div className='container content-header-ingress'>
              {WordpressMarkupParser(`<div>${page.acf.ingress}</div>`)}
            </div>
            {page.acf.buttons && <div className='container content-action-buttons'>
              {page.acf.buttons.map((button, key) => {
                if (button.is_external) {
                  return (
                    <a href={button.link}><Button className={key === 0 ? 'primary' : ''}>{button.text}</Button></a>
                  )
                } else {
                  return (
                    <Link key={key} href='[...slug]' as={button.link}><a><Button className={key === 0 ? 'primary' : ''}>{button.text}</Button></a></Link>
                  )
                }
              })}
            </div>}
          </div>
        </div>}
        <div className='container'>
          {page.acf.actions && <div className='bullet-points'>
            {page.acf.actions.map((action, key) => {
              return (
                <div key={key}>
                  {action.link && <Link href='[...slug]' as={action.link}><a><img src={`/img/${action.icon}.svg`} alt='' /></a></Link>}
                  {!action.link && <img src={`/img/${action.icon}.svg`} alt='' />}
                  {action.link && <Link href='[...slug]' as={action.link}><a><h3>{action.heading}</h3></a></Link>}
                  {!action.link && <h3>{action.heading}</h3>}
                  <p>{action.text}</p>
                  {action.link && <Link href='[...slug]' as={action.link}><a className='bullet-read-more'>Les mer</a></Link>}
                </div>
              )
            })}
          </div>}
          <div className='divider' />
          {page.acf.main_text && <div className='main-text'>
            {WordpressMarkupParser(`<div>${page.acf.main_text}</div>`)}
          </div>}
        </div>
        <style jsx>{`
          .content-header {
            background-image: url(${page.acf.banner_image.url});
            background-size: cover;
            background-position: 50% 50%;
            height: 25rem;
            border-radius: .4rem .4rem 0 0;
            padding: 1rem 2rem 3rem 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .content-header-text {
            width: 100%;
            max-width: 650px;
          }
          .text-inner {
            width: 100%;
          }
          .content-header-text h1 {
            color: #fff;
          }
          .content-header-text {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
          }
          .content-header-ingress {
            width: 100%;
            max-width: 650px;
            margin: 0 auto;
            text-align: center;
            font-size: 1.25rem;
          }
          .content-header-search {
            background-color: #fff;
            padding: .5rem;
          }
          .content-action-buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
          .content-action-buttons a {
            margin-left: .5rem;
            margin-right: .5rem;
          }
          .bullet-points {
            width: 100%;
            display: flex;
            padding-top: 2rem;
            justify-content: space-evenly;
            flex-wrap: wrap;
          }
          .bullet-points div {
            width: 15rem;
            padding: 1rem;
          }
          .bullet-points img {
            width: 5rem;
            margin: 0 auto;
            display: block;
          }
          .bullet-points h3 {
            margin-bottom: 0;
            font-size: 1.3rem;
            font-weight: 700;
          }
          .bullet-points a h3 {
            color: ${colors.link};
          }
          .bullet-points a {
            text-decoration: none;
          }
          .bullet-points a:hover {
            text-decoration: underline;
          }
          .bullet-points p {
            text-align: center;
            margin-bottom: 0;
          }
          .bullet-read-more {
            display: block;
            width: 100%;
            text-align: center;
          }
          .main-text {
            width: 100%;
            max-width: 650px;
            margin: 1rem auto 0 auto;
          }
          .divider {
            background: linear-gradient(90deg,${colors.primary} 0,${colors.primary} 16%,${colors.green} 0,${colors.green} 32%,${colors.orange} 0,${colors.orange} 50%,${colors.seaGreen} 0,${colors.seaGreen} 66%,${colors.pink} 0,${colors.pink} 83%,${colors.purple} 0,${colors.purple});
            height: 3px;
          }
          .error {
            color: red;
          }
          .another-wrapper {
            margin-top: 2rem;
            display: flex;
            justify-content: space-evenly;
            flex-direction: row-reverse;
          }
          .frontpage-text {
            padding: 1rem;
          }
          .frontpage-video {
            max-width: 560px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
            max-height: 315px;
          }
          .frontpage-video iframe {
            width: 560px;
            height: 315px;
          }
          @media (max-width: 550px) {
            .content-header {
              padding: 1rem;
              height: 15rem;
            }
            .bullet-points div {
              width: 100%;
            }
            .content-header {
              flex-direction: column;
            }
            .content-header-image {
              width: 100%;
            }
            .content-header-text {
              width: 100%;
            }
            .frontpage-video iframe {
              width: 336px;
              height: 189px;
            }
            .frontpage-video {
              max-height: 189px;
            }
          }
          @media (max-width: 900px) {
            .another-wrapper {
              flex-direction: column;
            }
            .frontpage-video {
              margin: 0 auto;
            }
          }
          @media (max-width: 1200px) {
            .content-header {
              border-radius: 0;
            }
          }
        `}</style>
      </div>
    )
  }
}

PageFront.propTypes = {
  page: PropTypes.object
}

export default PageFront
