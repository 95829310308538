import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showInflection, hideInflection } from 'redux/reducers/search'

class DictToggler extends React.Component {
  render () {
    const { children, visibleInflections, toggleId, toggleType, showInflection, hideInflection } = this.props
    const isVisible = visibleInflections.includes(toggleId)
    const toggleFunction = toggleType === 'show' ? showInflection : hideInflection
    return <div className='togglerWrapper'>
      {!isVisible && toggleType === 'show' && <button className='toggler' onClick={() => toggleFunction(toggleId)}>{children}</button>}
      {isVisible && toggleType === 'hide' && <button className='toggler' onClick={() => hideInflection(toggleId)}>{children}</button>}
      <style jsx>{`
      .togglerWrapper {
        display: inline;
      }
      .toggler {
        display: inline;
        padding: 0;
        color: #20399D;
        text-align: left;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0;
        text-transform: none;
        text-decoration: none;
        border: 0;
        border-radius: 0; 
      }
      `}</style>
    </div>
  }
}

DictToggler.propTypes = {
  children: PropTypes.any,
  toggleId: PropTypes.string,
  toggleType: PropTypes.string,
  visibleInflections: PropTypes.array,
  hideInflection: PropTypes.func,
  showInflection: PropTypes.func
}

const mapStateToProps = ({ search: { visibleInflections } }) => ({ visibleInflections })
const mapDispatchToProps = (dispatch) => {
  return {
    hideInflection: bindActionCreators(hideInflection, dispatch),
    showInflection: bindActionCreators(showInflection, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DictToggler)
