import React from 'react'
import Link from 'next/link'
import HtmlToReact, { Parser } from 'html-to-react'
import DictToggler from 'components/DictToggler'
import DictTogglable from 'components/DictTogglable'
import DictElement from 'components/DictElement'
import Highlighter from 'components/Highlighter'
import { containsPhrase } from 'utils/utils'
import omit from 'object.omit'

const parser = new Parser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

const isValidNode = (node) => {
  return true
}

const extractToggleId = (id) => {
  return id.replace(/^dict-toggl(er-show-|er-hide-|able-)/, '')
}

const regularProcess = {
  shouldProcessNode: (node) => true,
  processNode: processNodeDefinitions.processDefaultNode
}

const dictLinkProcess = (lang) => {
  return {
    shouldProcessNode: (node) => {
      return node.name === 'a' && node.attribs && node.attribs.href && node.attribs.href.startsWith('?search') && ['publications', '&'].every((str) => node.attribs.href.includes(str))
    },
    processNode: (node, children, index) => {
      const query = node.attribs.href.replace(/\?/i, '').split('&').reduce((obj, item) => {
        const queryItem = item.split('=')
        return queryItem.length !== 2 ? obj : { ...obj, [queryItem[0] === 'search' ? 'phrase' : queryItem[0]]: decodeURIComponent(queryItem[1]) }
      }, {})
      const queryWithLang = { ...query, lang }
      return <Link key={index} href={{ pathname: '/search', query: queryWithLang }} scroll={false}><a>{children}</a></Link>
    }
  }
}

const languageToolsLinkProcess = () => {
  return {
    shouldProcessNode: (node) => {
      return node.name === 'a' && node.attribs && node.attribs.href && (node.attribs.href.startsWith('https://www.ordnett.no') || node.attribs.href.startsWith('http://www.ordnett.no'))
    },
    processNode: (node, children, index) => {
      const asTo = node.attribs.href.startsWith('https://www.ordnett.no') ? node.attribs.href.replace(/https:\/\/www.ordnett.no/i, '') : node.attribs.href.startsWith('http://www.ordnett.no') ? node.attribs.href.replace(/http:\/\/www.ordnett.no/i, '') : node.attribs.href
      return <Link key={index} href='/sprakverktoy/[...slug]' as={asTo}><a>{children}</a></Link>
    }
  }
}

const dictTogglerElement = () => {
  return {
    shouldProcessNode: (node) => {
      return node.name === 'a' && node.attribs.id && node.attribs.id.startsWith('dict-toggler-')
    },
    processNode: (node, children, index) => {
      const toggleId = extractToggleId(node.attribs.id)
      const toggleType = node.attribs.id.includes('show') ? 'show' : 'hide'
      return React.createElement(DictToggler, { key: index, toggleId, toggleType }, children)
    }
  }
}

const dictTogglableElement = () => {
  return {
    shouldProcessNode: (node) => {
      return (node.name === 'div' || node.name === 'section') && node.attribs.id && node.attribs.id.startsWith('dict-togglable-')
    },
    processNode: (node, children, index) => {
      const toggleId = extractToggleId(node.attribs.id)
      return React.createElement(DictTogglable, { key: index, toggleId }, children)
    }
  }
}

const dictHighlightProcess = (textToBeHighlighted, className) => {
  return {
    shouldProcessNode: (node) => {
      const isHeadword = node.parent && node.parent.attribs && node.parent.attribs.class && (node.parent.attribs.class === 'headword' || node.parent.attribs.class === 'oppslagsord' || node.parent.attribs.class === 'hw' || node.parent.attribs.class === 'variant')
      return textToBeHighlighted && node.type === 'text' && !isHeadword && containsPhrase(node, textToBeHighlighted)
    },
    processNode: (node, children, index) => {
      return React.createElement(Highlighter, { key: index, textToBeHighlighted, className, node })
    }
  }
}

const wordpressLinkProcess = {
  shouldProcessNode: function (node) {
    return node.name === 'a' && node.attribs && node.attribs.href && (node.attribs.href.startsWith('/') || node.attribs.href.startsWith('https://cms.skoleordbok.no') || node.attribs.href.startsWith('https://www.ordnett.no'))
  },
  processNode: function (node, children, index) {
    const asTo = (node.attribs.href.startsWith('https://www.ordnett.no')) ? node.attribs.href.replace(/https:\/\/www.ordnett.no/i, '') : node.attribs.href
    const toArray = asTo.replace(/\/\s*$/, '').split('/')
    const to = toArray[toArray.length - 1]
    if (to === 'mypage' || to === 'minside' || to === 'search') {
      return <Link key={index} href={to}><a>{children}</a></Link>
    } else {
      return <Link key={index} href='/[...slug]' as={asTo}><a>{children}</a></Link>
    }
  }
}

const dictElementProcess = (elementRefid, setScrollToEle) => {
  return {
    shouldProcessNode: (node) => {
      return elementRefid && node.type === 'tag' && node.attribs['data-element-refid']
    },
    processNode: (node, children, index) => {
      return React.createElement(DictElement, { key: index, ...omit(node.attribs, ['class', 'style']), selected: node.attribs['data-element-refid'] === elementRefid, setScrollToEle }, children)
    }
  }
}

export const DictMarkupParser = (markup = '', { lang, textToBeHighlighted, setScrollToEle, elementRefid } = {}) => {
  return parser.parseWithInstructions(markup, isValidNode, [
    dictLinkProcess(lang),
    languageToolsLinkProcess(),
    dictTogglerElement(),
    dictTogglableElement(),
    dictHighlightProcess(textToBeHighlighted, 'highlight'),
    dictElementProcess(elementRefid, setScrollToEle),
    regularProcess
  ])
}

export const WordpressMarkupParser = (markup = '') => {
  return parser.parseWithInstructions(markup, isValidNode, [wordpressLinkProcess, regularProcess])
}
