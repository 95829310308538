import React from 'react'
import PropTypes from 'prop-types'
import { WordpressMarkupParser } from 'utils/markupParser'
import Head from 'next/head'

class PageContact extends React.Component {
  render () {
    const { page } = this.props
    return (
      <div className='page-container'>
        <Head>
          {page.acf.title && <title>{page.acf.title}</title>}
          {page.acf.description && <meta name='description' content={page.acf.description} />}
          {page.acf.title && <meta property='og:title' content={page.acf.title} />}
          {page.acf.description && <meta property='og:description' content={page.acf.description} />}
          {page.acf.image_og && <meta property='og:image' content={page.acf.image_og} />}
          {<meta property='og:url' content='https://skole.ordnett.no' />}
          {page.acf.title && <meta name='twitter:title' content={page.acf.title} />}
          {page.acf.description && <meta name='twitter:description' content={page.acf.description} />}
          {page.acf.image_twitter && <meta name='twitter:image' content={page.acf.image_twitter} />}
          {<meta name='twitter:card' content='summary_large_image' />}
        </Head>
        {page && !page.acf.display_title && <h1>{page.title.rendered}</h1>}
        {page && page.acf.display_title && <h1>{page.acf.display_title}</h1>}
        {page && page.acf && page.acf.contact_person && this.renderContactPerson(page.acf.contact_person)}
        {page && WordpressMarkupParser(`<div className='cms-content'>${page.content.rendered}</div>`)}
        <style jsx global>{`
        h1, h2, h3 {
          text-align: left;
        }
        `}</style>
      </div>
    )
  }

  renderContactPerson = (persons) => {
    return (
      persons.map((person, key) => {
        return (
          <div className='person-wrapper' key={key}>
            <div className='person-image'><img src={person.image.url} title={person.name} alt={person.name}/></div>
            <div className='person-name'><strong>{person.name}</strong></div>
            <div className='person-email'>{person.email}</div>
            <div className='person-phone'>{person.phone}</div>
            <style jsx global>{`
              .person-wrapper {
                box-shadow: 
                  0 2px 2px 0 rgba(0, 0, 0, 0.14),
                  0 3px 1px -2px rgba(0, 0, 0, 0.2),
                  0 1px 5px 0 rgba(0, 0, 0, 0.12);
                padding: 1rem;
                width: 300px;
              }
              .person-image {
                width: 84px;
                height: 84px;
                margin-bottom: .5rem;
              }
            `}</style>
          </div>
        )
      })
    )
  }
}

PageContact.propTypes = {
  page: PropTypes.object
}

export default PageContact
