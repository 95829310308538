import React from 'react'
import PropTypes from 'prop-types'
import { escapeStringRegexp, isSeparateWord, containsPhrase } from 'utils/utils'

const Highlighter = ({ node, className = '', textToBeHighlighted = '' }) => {
  return (
    <span>
      {markPhrase(node, textToBeHighlighted, className)}
    </span>
  )
}

const markPhrase = (node, phrase, className) => {
  const normalizedPhrase = phrase ? phrase.trim().replace(/\s+/, ' ') : ''
  const text = node.data
  if (!normalizedPhrase || !containsPhrase(node, phrase)) {
    return [text]
  }
  const parts = normalizedPhrase.split(/ /)
  return markWord(text, parts, className, [])
}

const markWord = (text, parts, className, accFragment) => {
  if (parts.length < 1) {
    return [...accFragment, <span key={accFragment.length + 1}>{text}</span>]
  }
  const part = parts[0]
  const partRegex = new RegExp(escapeStringRegexp(part), 'i')
  const match = text.match(partRegex)
  const pos = match ? match.index : -1
  const remainingParts = (pos > -1 && !isSeparateWord(part, text, pos)) ? parts.slice(0) : parts.slice(1)
  const fragment = (pos > -1 && isSeparateWord(part, text, pos))
    ? [<span key={accFragment.length + 1}>{text.substr(0, pos)}</span>, <mark className={className} key={accFragment.length + 2}>{text.substr(pos, part.length)}</mark>]
    : [<span key={accFragment.length + 1}>{text.substr(0, pos + part.length)}</span>]
  return markWord(text.substring(pos + part.length), remainingParts, className, accFragment.concat(fragment))
}

Highlighter.propTypes = {
  node: PropTypes.object,
  className: PropTypes.string,
  textToBeHighlighted: PropTypes.string
}

export default Highlighter
