import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class DictTogglable extends React.Component {
  render () {
    const { children, toggleId, visibleInflections } = this.props
    const isVisible = visibleInflections && visibleInflections.length > 0 && visibleInflections.includes(toggleId)
    if (!isVisible) {
      return null
    }
    return (
      <div className='togglable'>
        {children}
      </div>
    )
  }
}

DictTogglable.propTypes = {
  children: PropTypes.any,
  toggleId: PropTypes.string,
  visibleInflections: PropTypes.array
}

const mapStateToProps = ({ search: { visibleInflections } }) => ({ visibleInflections })
export default connect(mapStateToProps)(DictTogglable)
